import React, { Component } from 'react'

interface SearchProps {
  changeSearch: (searchText: string) => void
  placeholder: string
  title: string
  initValue: string
}
class Search extends Component<SearchProps> {
  changeSearch(event) {
    if (event.key === 'Enter') {
      this.props.changeSearch(event.target.value)
    }
  }

  resetSearch() {
    this.refs.filter_string['value'] = ''
    this.props.changeSearch(null)
  }
  render() {
    return (
      <div className="filter-box">
        <div className="projectNumber relative">
          <label htmlFor="filter_string">Filter results</label>
          <input
            ref="filter_string"
            className="search-box input-reset"
            type="text"
            onKeyPress={this.changeSearch.bind(this)}
            placeholder={this.props.placeholder}
            defaultValue={this.props.initValue}
          />
          <div className="line-search" />
          <div
            className="search-x dim pointer"
            onClick={this.resetSearch.bind(this)}
          >
            <div className="text">x </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Search
