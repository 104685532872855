import React, { Component } from 'react'

interface ResourceAttributeRowProps {
  title: string
  value: string
}

class ResourceAttributeRow extends Component<ResourceAttributeRowProps> {
  render() {
    const { title, value } = this.props
    return (
      <li className="lh-copy pa1 ph0-l">
        <div className="dt dt--fixed w-100">
          <div className="dtc mt2">
            <span className="title">{title} </span>
          </div>
          <div className="dtc pl3 mt2 ">
            <span className="value">{value}</span>
          </div>
        </div>
      </li>
    )
  }
}

export default ResourceAttributeRow
