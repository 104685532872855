import React, { Component } from 'react'
import { Resource } from '../../schema/types.d'
import ResourceStamp from './ResourceStamp'
import Details from './Details'
import Registrations from './Registrations'
import Projects from './Projects'

enum navTabs {
  details = 'Details',
  registrations = 'Registrations',
  skills = 'Skills',
  projects = 'Projects'
}

interface TitleState {
  active: boolean
}
interface TitleProps {
  name: string
  active: boolean
  onClick: () => void
  disabled: boolean
}

class NavigationTitle extends Component<TitleProps, TitleState> {
  constructor(props) {
    super(props)
    if (this.props.name == navTabs.details) this.state = { active: true }
    else this.state = { active: false }
  }

  render() {
    const activeClass = this.props.active ? ' active' : ' inactive'
    const disabledClass = this.props.disabled ? ' disabled' : ' pointer'
    return (
      <li
        className={'tab-item' + activeClass + disabledClass}
        onClick={
          this.props.disabled ? undefined : this.props.onClick.bind(this)
        }
      >
        <a>{this.props.name}</a>
      </li>
    )
  }
}

interface ResourceDetailsTabState {
  currentPage: string
}

interface ResourceDetailsTabProps {
  resource: Resource.Resource
}

class ResourceDetailsTab extends Component<
  ResourceDetailsTabProps,
  ResourceDetailsTabState
> {
  constructor(props) {
    super(props)
    this.state = { currentPage: navTabs.details }
  }

  mainPage(resource: Resource.Resource) {
    switch (this.state.currentPage) {
      case navTabs.registrations:
        return <Registrations registrations={resource.registrations} />
      case navTabs.skills:
        return <div />
      case navTabs.projects:
        return (
          <Projects
            Historical={resource.currentProjects}
            Planned={resource.plannedProjects}
          />
        )
      default:
        return <Details resource={resource} />
    }
  }

  render() {
    const resource = this.props.resource
    const pages = Object.values(navTabs)
    const disabled_tabs = new Map<string, boolean>([
      [navTabs.details, false],
      [navTabs.skills, true],
      [navTabs.registrations, resource.registrations.length == 0],
      [navTabs.projects, false]
    ])
    return (
      <div className="details-menu">
        <ResourceStamp resource={this.props.resource} />
        <div className="pl5 tabHolder">
          {pages.map(p => (
            <NavigationTitle
              key={p}
              name={p}
              onClick={() => this.setState({ currentPage: p })}
              active={this.state.currentPage == p ? true : false}
              disabled={disabled_tabs.get(p)}
            />
          ))}
        </div>
        {this.mainPage(resource)}
      </div>
    )
  }
}

export default ResourceDetailsTab
