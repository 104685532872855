import React, { Component } from 'react'
import InputRange from 'react-input-range'

interface RangeSliderProps {
  title: string
  range: number[]
  rangeSelections: number[]
  onRangeChange: (title: string, range: number[]) => void
}

interface RangeSliderState {
  value:
    | number
    | {
        min: number
        max: number
      }
}
class RangeSlider extends Component<RangeSliderProps, RangeSliderState> {
  onRangeChanged(value: number | { min: number; max: number }) {
    this.setState({ value })
    if (typeof value != 'number')
      this.props.onRangeChange(this.props.title, [value.min, value.max])
  }
  render() {
    const { title, range, rangeSelections } = this.props
    const elementId = title + '_collapsible'
    const value =
      this.props.rangeSelections.length > 0
        ? {
            min:
              this.props.rangeSelections[0] >= this.props.range[0]
                ? this.props.rangeSelections[0]
                : this.props.range[0],
            max:
              this.props.rangeSelections[1] <= this.props.range[1]
                ? this.props.rangeSelections[1]
                : this.props.range[1]
          }
        : { min: this.props.range[0], max: this.props.range[1] }

    return (
      <div className="wrap-collabsible mt3">
        <input
          id={elementId}
          className="collapsible toggle"
          type="checkbox"
          defaultChecked={rangeSelections.length > 0}
        />
        <label htmlFor={elementId} className="lbl-toggle filter-title">
          {title}
        </label>
        <div className="collapsible-content">
          <div className="content-inner">
            <InputRange
              minValue={range[0]}
              maxValue={range[1]}
              value={value}
              onChange={value => this.onRangeChanged(value)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default RangeSlider
