import React, { Component } from 'react'

class Spinner extends Component {
  render() {
    return (
      <div>
        <div className="spinner" />
        <div className="spinner-text">Loading results</div>
      </div>
    )
  }
}

export default Spinner
