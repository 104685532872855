import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'

class Header extends Component<RouteComponentProps> {
  render() {
    return (
      <div className="rectangle-copy-3 center">
        <nav className="dt w-100 center">
          <span>
            <img
              className="logo"
              src="/HKS-Logo-Grey-Digital_N2.jpg"
              alt="HKS"
            />
            <span className="resource-planner"> Resource Planner </span>
          </span>
          {/* <div className="dtc v-mid tr pa1">
            <a className="hover-black no-underline top-link" href="/">
              Employees
            </a>
            <a
              className="hover-black no-underline top-link pl3 pr3"
              href="/projects"
            >
              Active Projects
            </a>
          </div> */}
        </nav>
      </div>
    )
  }
}

export default withRouter(Header)
