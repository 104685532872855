import React, { Component } from 'react'
import { ProjectInfo } from 'src/schema/types.d'
import Pagination, { PaginationState } from './Pagination'

enum SortBy {
  projectNumber = 'projectNumber',
  name = 'name',
  practice = 'practiceArea'
}

interface HistoricalProps {
  projects: ProjectInfo.Fragment[]
  sortClicked: (field: string) => void
  activeSort: string
}

class HistoricalProjects extends Component<HistoricalProps> {
  render() {
    const sortedProjects = this.props.projects
    const titles = ['PROJECT #', 'PROJECT NAME', 'PRACTICE', 'LABOR CATEGORY']
    const fields = ['projectNumber', 'name', 'practiceArea', 'laborCategory']
    return (
      <ProjectsTable
        titles={titles}
        fields={fields}
        projects={sortedProjects}
        sortClicked={this.props.sortClicked}
        activeSort={this.props.activeSort}
      />
    )
  }
}

interface PlannedProps {
  projects: ProjectInfo.Fragment[]
  sortClicked: (field: string) => void
  activeSort: string
}

interface TableProps {
  titles: string[]
  fields: string[]
  projects: ProjectInfo.Fragment[]
  sortClicked: (field: string) => void
  activeSort: string
}
function formatDate(dateString: string): string {
  if (dateString) {
    let d = new Date(parseInt(dateString))
    return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
  } else return ''
}

function compareDates(d1: string, d2: string, ascending: boolean) {
  if (d1 && d2) {
    let a = parseInt(d1)
    let b = parseInt(d2)
    return ascending ? a - b : b - a
  } else
    return ascending ? d1 || ''.localeCompare(d2) : d2 || ''.localeCompare(d1)
}
class ProjectsTable extends Component<TableProps> {
  render() {
    const { titles, fields, projects, sortClicked, activeSort } = this.props
    return (
      <table className="projects center">
        <thead>
          <tr className="projectName v-mid">
            {titles.map((t, i) => (
              <th key={i}>
                <div
                  className="sort dim pointer"
                  onClick={e => sortClicked(fields[i])}
                >
                  <img alt="" src="/sort.png" />
                </div>
                <div className={activeSort == fields[i] ? 'active' : ''}>
                  {t}{' '}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="projectNumber v-mid">
          {projects.map((p, i) => (
            <tr key={i}>
              {fields.map((f, index) => (
                <td key={index}>
                  {f.toLowerCase().includes('date') ? formatDate(p[f]) : p[f]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
}

class PlannedProjects extends Component<PlannedProps> {
  render() {
    const sortedProjects = this.props.projects
    const titles = [
      'PROJECT #',
      'PROJECT NAME',
      'START DATE',
      'END DATE',
      'TOTAL HOURS',
      'PRACTICE',
      'LABOR CATEGORY'
    ]
    const fields = [
      'projectNumber',
      'name',
      'startDate',
      'completionDate',
      'totalHours',
      'practiceArea',
      'laborCategory'
    ]
    return (
      <ProjectsTable
        titles={titles}
        fields={fields}
        projects={sortedProjects}
        sortClicked={this.props.sortClicked.bind(this)}
        activeSort={this.props.activeSort}
      />
    )
  }
}

interface ProjectProps {
  Historical: ProjectInfo.Fragment[]
  Planned: ProjectInfo.Fragment[]
}
enum ProjectType {
  historical = 'Historical',
  planned = 'Planned'
}

interface ProjectState {
  show: string
  sortBy: string
  ascending: boolean
  currentPage: number
  totalPages: number
}
class Projects extends Component<ProjectProps, ProjectState> {
  pageLimit = 12
  constructor(props) {
    super(props)
    this.state = {
      show: ProjectType.historical,
      sortBy: SortBy.projectNumber,
      ascending: true,
      currentPage: null,
      totalPages: null
    }
    this.sortClicked = this.sortClicked.bind(this)
    this.onPageChanged = this.onPageChanged.bind(this)
  }

  onPageChanged = (data: PaginationState) => {
    const { currentPage, totalPages } = data
    this.setState({ currentPage, totalPages })
  }

  sortClicked(field: string) {
    let { sortBy, ascending } = this.state
    ascending = field == sortBy ? !ascending : true
    this.setState({ sortBy: field, ascending: ascending })
  }
  projectsHeader(totalProjects: number) {
    const { currentPage } = this.state
    const end_resource_no =
      currentPage * this.pageLimit > totalProjects
        ? totalProjects
        : currentPage * this.pageLimit
    const start_resource_no =
      totalProjects == 0
        ? totalProjects
        : currentPage * this.pageLimit - this.pageLimit + 1
    return (
      <div className="pt5 pl6 pb4 dt">
        <div className="dtc projectNumber">
          Viewing {start_resource_no}-{end_resource_no} of {totalProjects}
          <div className="dtc line-3 ml3" />
        </div>
        <div className="dtc sort-by pr2"> View:</div>
        <div className="dtc">
          <select
            className="pl2 projectNumber sort-box"
            value={this.state.show}
            onChange={e => this.setState({ show: e.target.value })}
          >
            <option value={ProjectType.historical}>Current/Historical</option>
            <option value={ProjectType.planned}>{ProjectType.planned}</option>
          </select>
        </div>
      </div>
    )
  }

  render() {
    const projectsShown: ProjectInfo.Fragment[] = this.props[this.state.show]
    const { sortBy, ascending } = this.state
    const sortedProjects = projectsShown.sort((a, b) => {
      if (sortBy.toLowerCase().includes('date'))
        return compareDates(a[sortBy], b[sortBy], ascending)
      else
        return ascending
          ? typeof a[sortBy] == 'string'
            ? a[sortBy].localeCompare(b[sortBy])
            : a[sortBy] - b[sortBy]
          : typeof a[sortBy] == 'string'
          ? b[sortBy].localeCompare(a[sortBy])
          : b[sortBy] - a[sortBy]
    })

    const { currentPage } = this.state
    const offset = (currentPage - 1) * this.pageLimit
    const currentProjects = sortedProjects.slice(
      offset,
      offset + this.pageLimit
    )

    return (
      <div>
        {this.projectsHeader(sortedProjects.length)}
        {this.state.show == ProjectType.historical ? (
          <div>
            <HistoricalProjects
              projects={currentProjects}
              sortClicked={this.sortClicked}
              activeSort={this.state.sortBy}
            />
            <div className="ma4 pl2 fl">
              <Pagination
                totalRecords={sortedProjects.length}
                pageLimit={this.pageLimit}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            </div>
          </div>
        ) : (
          <div>
            <PlannedProjects
              projects={currentProjects}
              sortClicked={this.sortClicked}
              activeSort={this.state.sortBy}
            />
            <div className="ma4 pl2 fl">
              <Pagination
                totalRecords={sortedProjects.length}
                pageLimit={this.pageLimit}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Projects
