import React, { Component } from 'react'
import { ResourceInfo } from 'src/schema/types.d'
import FilterList from './FilterList'
import ResourcesList, { SortBy } from './ResourcesList'
import { FilterTitle } from './ResourcesBench'

export function filterResources(
  res: ResourceInfo.Fragment[],
  filterBy: Map<string, any[]>
) {
  const office_filters = filterBy.get(FilterTitle.office)
  const practice_filters = filterBy.get(FilterTitle.practice)
  const labor_filters = filterBy.get(FilterTitle.laborCategory)
  const yearsWithHKSRange = filterBy.get(FilterTitle.yearsWithHks)
  const totalYearsRange = filterBy.get(FilterTitle.totalEmploymentYears)
  const filtered = res.filter(
    r =>
      (office_filters.length == 0 || office_filters.some(o => r.office == o)) &&
      (practice_filters.length == 0 ||
        practice_filters.some(p => r.practice == p)) &&
      (labor_filters.length == 0 ||
        labor_filters.some(l => r.laborCategory == l)) &&
      (totalYearsRange.length == 0 ||
        (r.totalEmploymentYears >= totalYearsRange[0] &&
          r.totalEmploymentYears <= totalYearsRange[1])) &&
      (yearsWithHKSRange.length == 0 ||
        (r.yearsWithHks >= yearsWithHKSRange[0] &&
          r.yearsWithHks <= yearsWithHKSRange[1]))
  )
  return filtered
}

interface RFProps {
  filters: Map<string, any[]>
  resources: ResourceInfo.Fragment[]
  filterBy: Map<string, any[]>
  sortBy: string
  onFilterToggle: (title: string, value: string) => void
  onRangeChange: (title: string, range: number[]) => void
  onClearFilters: () => void
  onSortChanged: (e) => void
  searchText: string
}

interface RFState {}

class ResourcesFilterList extends Component<RFProps, RFState> {
  render() {
    const { filters, resources, filterBy, sortBy, onSortChanged } = this.props
    const filteredResources = filterResources(resources, filterBy)
    return (
      <div>
        <FilterList
          filters={filters}
          filterBy={filterBy}
          onFilterToggle={this.props.onFilterToggle}
          onRangeChange={this.props.onRangeChange}
          onClearFilters={this.props.onClearFilters}
        />
        <ResourcesList
          res={filteredResources}
          sortBy={sortBy}
          onSortChanged={onSortChanged}
          searchText={this.props.searchText}
        />
      </div>
    )
  }
}

export default ResourcesFilterList
