import React, { Component, Fragment } from 'react'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }
  return range
}

export interface PaginationState {
  currentPage: number
  totalPages: number
}
interface PaginationProps {
  totalRecords: number
  pageLimit: number
  pageNeighbours: number
  onPageChanged: (data: PaginationState) => void
}
class Pagination extends Component<PaginationProps, PaginationState> {
  pageLimit: number
  totalRecords: number
  pageNeighbours: number
  totalPages: number

  constructor(props) {
    super(props)
    const { totalRecords = null, pageLimit = 20, pageNeighbours = 0 } = props

    this.pageLimit = pageLimit
    this.totalRecords = totalRecords

    this.pageNeighbours = Math.max(0, Math.min(pageNeighbours, 2))

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit)
    this.state = { currentPage: 1, totalPages: this.totalPages }
  }

  componentDidMount() {
    this.gotoPage(1)
  }

  gotoPage = page => {
    const currentPage = Math.max(0, Math.min(page, this.totalPages))

    const paginationData = {
      currentPage,
      totalPages: this.totalPages
    }

    this.setState({ currentPage }, () =>
      this.props.onPageChanged(paginationData)
    )
  }

  handleClick = (page, evt) => {
    evt.preventDefault()
    this.gotoPage(page)
  }

  handleMoveLeft = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1)
  }

  handleMoveRight = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1)
  }

  fetchPageNumbers = () => {
    const totalPages = this.totalPages
    const currentPage = this.state.currentPage
    const pageNeighbours = this.pageNeighbours

    const totalNumbers = this.pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      let pages = []

      const leftBound = currentPage - pageNeighbours
      const rightBound = currentPage + pageNeighbours
      const beforeLastPage = totalPages - 1

      const startPage = leftBound > 2 ? leftBound : 2
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage

      pages = range(startPage, endPage)

      const pagesCount = pages.length
      const singleSpillOffset = totalNumbers - pagesCount - 1

      const leftSpill = startPage > 2
      const rightSpill = endPage < beforeLastPage

      const leftSpillPage = LEFT_PAGE
      const rightSpillPage = RIGHT_PAGE

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1)
        pages = [leftSpillPage, ...extraPages, ...pages]
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset)
        pages = [...pages, ...extraPages, rightSpillPage]
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage]
      }

      return [1, ...pages, totalPages]
    }

    return range(1, totalPages)
  }

  render() {
    const { totalRecords, pageLimit, pageNeighbours } = this.props
    this.totalPages = Math.ceil(totalRecords / pageLimit)
    if (!totalRecords) return null
    if (this.state.currentPage > this.totalPages) this.gotoPage(1)
    if (this.totalPages === 1) return null
    const { currentPage } = this.state
    const pages = this.fetchPageNumbers()

    return (
      <Fragment>
        <nav aria-label="Employees Pagination">
          <ul className="pagination">
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <li
                    key={index}
                    className="page-item pointer"
                    onClick={this.handleMoveLeft}
                  >
                    <div className="page-link b" aria-label="Previous">
                      {' '}
                      {'<<'}
                    </div>
                  </li>
                )

              if (page === RIGHT_PAGE)
                return (
                  <li
                    key={index}
                    className="page-item pointer b"
                    onClick={this.handleMoveRight}
                  >
                    <div className="page-link" aria-label="Next">
                      {' '}
                      >>
                    </div>
                  </li>
                )

              return (
                <li
                  key={index}
                  className={`pointer page-item${
                    currentPage === page ? ' active' : ''
                  }`}
                  onClick={e => this.handleClick(page, e)}
                >
                  <div className="page-link">{page}</div>
                </li>
              )
            })}
          </ul>
        </nav>
      </Fragment>
    )
  }
}
export default Pagination
