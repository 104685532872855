import React, { Component } from 'react'
import { ResourceInfo } from 'src/schema/types.d'

interface ResourceStampProps {
  resource: ResourceInfo.Fragment
}
class ResourceStamp extends Component<ResourceStampProps> {
  addDefaultImage(ev) {
    ev.target.src = '/defaultAvatar.png'
  }

  render() {
    const resource = this.props.resource
    return (
      <div className="dt pa2 w-100">
        <div className="dtc pl5 pa4 w-20">
          <img
            alt=""
            src={resource.thumbnailUrl}
            onErrorCapture={this.addDefaultImage}
            className="photo dib"
            title={`Photo of ${resource.fullName}`}
          />
        </div>
        <div className="dtc v-mid w-60">
          <div className="employee-name">{resource.fullName}</div>
          <div className="employee-details pt2">
            <span className="pr2">{resource.title} </span> |{' '}
            <span className="pl2">{resource.office}</span>
          </div>
          <div className="add">Add to a project</div>
        </div>
        <div className="dtc v-mid">
          {resource.fte < 1 ? (
            <div>
              <div className="dt pl2">
                <img
                  alt=""
                  src="/baseline-alarm_off.png"
                  className="dtc partTime"
                />
                <div className="dtc pl2 employee-details v-mid">
                  {'FTE: ' + resource.fte}
                </div>
              </div>
              <div className="line-5" />
            </div>
          ) : null}

          {resource.onLeave ? (
            <div>
              <div className="dt pl2">
                <img alt="" src="/onLeave.png" className="dtc onLeave" />
                <div className="dtc pl2 employee-details v-mid">On Leave </div>
              </div>
              <div className="line-5" />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default ResourceStamp
