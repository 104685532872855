import React, { Component } from 'react'
import { ResourceInfo } from '../../schema/types.d'
import { withRouter } from 'react-router'

interface Props {
  resource: ResourceInfo.Fragment
}

// export function trimTitle(title: string): string {
//   return title.split(' and ')[0]
// }
class ResourceThumbnail extends Component<any> {
  addDefaultImage(ev) {
    ev.target.src = '/defaultAvatar.png'
  }

  render() {
    const resource = this.props.resource
    return (
      <a
        className="employee-link"
        onClick={() => this.props.history.push('/resource/' + resource.id)}
      >
        <article className="employee-box pointer">
          <div className="tn_icons">
            {resource.fte < 1 ? (
              <div className="partTime hide-child">
                <img alt="" src="baseline-alarm_off.png" />
                <div className="child onleave-hover">
                  {'FTE: ' + resource.fte}
                </div>
              </div>
            ) : null}
            {resource.onLeave ? (
              <div className="onLeave hide-child">
                <img alt="" src="onLeave.png" />
                <div className="onleave-hover child">On Leave</div>
              </div>
            ) : null}
          </div>
          <img
            alt=""
            src={resource.thumbnailUrl}
            onErrorCapture={this.addDefaultImage}
            className="br-100 photo dib"
            title={`Photo of ${resource.fullName}`}
          />
          <div className="employee-name">
            {resource.firstName} {resource.lastName}
          </div>
          <div className="employee-details">{resource.title}</div>
          <div className="line-2" />
          <div className="employee-details">
            <div>{resource.laborCategory}</div>
            <div>{resource.practice}</div>
            <div>{resource.profitCenter}</div>
          </div>
        </article>
      </a>
    )
  }
}

export default withRouter(ResourceThumbnail)
