import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class HKSError extends Component {
  render() {
    return (
      <div className="error-wrapper">
        <FontAwesomeIcon icon="exclamation-triangle" className="error" />
        <div className="error-title mt2">Sorry, something went wrong.</div>
        <div className="error-text mt2">
          {'Please refresh the page. If the problem persists, please email '}
          <a href="mailto: helpdesk@hksinc.com">helpdesk@hksinc.com</a>
        </div>
      </div>
    )
  }
}

export default HKSError
