import React, { Component, Props } from 'react'
import { Resource } from '../../schema/types.d'
import ResourceDetailsTab from './ResourceDetailsTab'
import Spinner from '../Spinner'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import HKSError from '../HKSError'

class ResourceDetailsPage extends Component<
  RouteComponentProps<{ id: string }>
> {
  render() {
    return (
      <Resource.Component
        fetchPolicy="no-cache"
        variables={this.props.match.params}
      >
        {({ loading, error, data }) => {
          if (loading) return <Spinner />
          if (error) return <HKSError />
          if (!data)
            return <div className="zero-results">Sorry, no results found</div>
          const resource = data.resource
          // TODO FIXME
          if (!resource) return <div />

          return (
            <div className="w-75 center">
              <div className="project-page-heading pb2">Bench</div>
              <div className="details">
                <a
                  className="dt ma2 pa2 dim pointer"
                  onClick={() => {
                    this.props.history.push('/')
                  }}
                >
                  {' '}
                  <div className="dtc back-arrow">{'<'}</div>
                  <div className="dtc back">Back </div>
                </a>

                <div className="line-4" />
                <ResourceDetailsTab resource={resource} />
              </div>
            </div>
          )
        }}
      </Resource.Component>
    )
  }
}
export default withRouter(ResourceDetailsPage)
