import React, { Component } from 'react'
import { ResourceInfo } from '../../schema/types.d'
import ResourceAttributeRow from './ResourceAttributeRow'

interface DetailsProps {
  resource: ResourceInfo.Fragment
}

class Details extends Component<DetailsProps> {
  render() {
    const resource = this.props.resource
    const detailAttributes: string[][] = [
      ['Labor Category:', resource.laborCategory],
      ['Profit Center:', resource.profitCenter],
      ['Physical Location:', resource.office],
      ['Practice:', resource.practice],
      ['Years With HKS:', '' + resource.yearsWithHks],
      [
        'Total Employment Years:',
        resource.totalEmploymentYears
          ? '' + resource.totalEmploymentYears
          : 'not available'
      ],
      ['Team:', resource.team]
    ]
    return (
      <article className="dt pl5 pa4">
        <div className="dtc v-mid pl2">
          <ul className="list pl0 mt0 measure center">
            {detailAttributes.map((detail, index) => (
              <ResourceAttributeRow
                key={index}
                title={detail[0]}
                value={detail[1]}
              />
            ))}
            <li className="lh-copy pa1 ph0-l">
              <div className="dt dt--fixed">
                <div className="dtc mt2">
                  <span className="title">Direct Labor Rate: </span>
                </div>
                <div className="dtc pl3 mt2 wrapper">
                  <div className="value rate rate-box1">
                    {resource.realRate ? 'Hover to see rate' : 'not available'}
                  </div>
                  <div className="rate-box2 value">
                    {/* {resource.realRate} */}
                    {resource.realRate ? 'Masked' : 'not available'}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </article>
    )
  }
}
export default Details
