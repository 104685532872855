import React, { Component } from 'react'
import '../styles/App.scss'
import '../styles/Hks.css'
import Header from './Header'
import { Switch, Route } from 'react-router-dom'
import ResourcesBench from './resources/ResourcesBench'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  fas,
  faChartLine,
  faTable,
  faBars,
  faMapMarker
} from '@fortawesome/free-solid-svg-icons'

library.add(fas, faChartLine, faTable, faBars, faMapMarker)

class App extends Component {
  render() {
    return (
      <div className="rectangle-copy center w-100">
        <Header />
        <div>
          <Switch>
            <Route exact path="/" component={ResourcesBench} />
            <Route exact path="/resource/:id" component={ResourcesBench} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default App
