import React, { Component } from 'react'
import { Resources, ResourceInfo } from 'src/schema/types.d'
import Search from './Search'
import ResourcesFilterList, { filterResources } from './ResourcesFilterList'
import Spinner from '../Spinner'
import { RouteComponentProps } from 'react-router'
import ResourceDetailsPage from './ResourceDetailsPage'
import { SortBy } from './ResourcesList'
import HKSError from '../HKSError'

interface BenchState {
  searchText: string
  resources: ResourceInfo.Fragment[]
  filterBy: Map<string, any[]>
  sortBy: string
}

interface BenchProps {
  id: string
}

export enum FilterTitle {
  laborCategory = 'LABOR CATEGORY',
  practice = 'PRACTICE',
  office = 'OFFICE',
  totalEmploymentYears = 'TOTAL EMPLOYMENT YEARS',
  yearsWithHks = 'YEARS WITH HKS'
}

class ResourcesBench extends Component<
  RouteComponentProps<BenchProps>,
  BenchState
> {
  componentDidUpdate() {
    if (this.props.match.params.id) {
      window.scrollTo(0, 0)
    }
  }
  constructor(props: RouteComponentProps<BenchProps>) {
    super(props)
    this.state = {
      searchText: null,
      resources: null,
      filterBy: new Map([
        [FilterTitle.office, []],
        [FilterTitle.practice, []],
        [FilterTitle.laborCategory, []],
        [FilterTitle.totalEmploymentYears, []],
        [FilterTitle.yearsWithHks, []]
      ]),
      sortBy: SortBy.default
    }
    this.changeSearch = this.changeSearch.bind(this)
    this.onSortChanged = this.onSortChanged.bind(this)
  }

  onSortChanged = (e: any) => {
    this.setState({ sortBy: e.target.value })
  }
  onFilterToggle = (title: string, value: string) => {
    let filters = this.state.filterBy
    let index_of_value = filters.get(title).indexOf(value)
    index_of_value == -1
      ? filters.get(title).push(value)
      : filters.get(title).splice(index_of_value, 1)
    this.setState({ filterBy: filters })
  }

  onRangeChange = (title: string, range: number[]) => {
    let filters = this.state.filterBy
    filters.set(title, range)
    this.setState({ filterBy: filters })
  }

  clearFilters = () => {
    this.setState({
      filterBy: new Map([
        [FilterTitle.office, []],
        [FilterTitle.practice, []],
        [FilterTitle.laborCategory, []],
        [FilterTitle.totalEmploymentYears, []],
        [FilterTitle.yearsWithHks, []]
      ])
    })
  }

  changeSearch(searchText: string) {
    this.setState({
      searchText: searchText,
      resources: null,
      filterBy: new Map([
        [FilterTitle.office, []],
        [FilterTitle.practice, []],
        [FilterTitle.laborCategory, []],
        [FilterTitle.totalEmploymentYears, []],
        [FilterTitle.yearsWithHks, []]
      ])
    })
  }

  pruneFilterLists(
    resources: ResourceInfo.Fragment[],
    filterBy: Map<string, any[]>
  ): Map<string, Array<[string, number]> | Array<number>> {
    let offices,
      practices,
      laborCategories,
      office_list: Array<[string, number]>,
      practice_list: Array<[string, number]>,
      laborCategory_list: Array<[string, number]>
    offices = new Set()
    practices = new Set()
    laborCategories = new Set()
    office_list = new Array<[string, number]>()
    practice_list = new Array<[string, number]>()
    laborCategory_list = new Array<[string, number]>()
    const filters_for_office = new Map(filterBy).set(FilterTitle.office, [])
    const filters_for_practice = new Map(filterBy).set(FilterTitle.practice, [])
    const filters_for_labor = new Map(filterBy).set(
      FilterTitle.laborCategory,
      []
    )
    const filters_for_years = new Map(filterBy)
      .set(FilterTitle.yearsWithHks, [])
      .set(FilterTitle.totalEmploymentYears, [])
    const office_resources = filterResources(resources, filters_for_office)
    const practice_resources = filterResources(resources, filters_for_practice)
    const labor_resources = filterResources(resources, filters_for_labor)
    const years_resources = filterResources(resources, filters_for_years)
    office_resources.map(r => (r.office ? offices.add(r.office) : null))
    practice_resources.map(r => (r.practice ? practices.add(r.practice) : null))
    labor_resources.map(r =>
      r.laborCategory ? laborCategories.add(r.laborCategory) : null
    )
    const officesArray = [...offices]
    const practicesArray = [...practices]
    const laborArray = [...laborCategories]
    officesArray.map(o =>
      office_list.push([o, office_resources.filter(r => r.office == o).length])
    )
    practicesArray.map(p =>
      practice_list.push([
        p,
        practice_resources.filter(r => r.practice == p).length
      ])
    )
    laborArray.map(l =>
      laborCategory_list.push([
        l,
        labor_resources.filter(r => r.laborCategory == l).length
      ])
    )

    const totalEmpYearsRange = [
      0,
      Math.ceil(
        Math.ceil(
          Math.max(...years_resources.map(r => r.totalEmploymentYears))
        ) / 10
      ) * 10
    ]

    const yearsHksRange = [
      0,
      Math.ceil(
        Math.ceil(Math.max(...years_resources.map(r => r.yearsWithHks))) / 10
      ) * 10
    ]
    let total_filters = new Map<
      string,
      Array<[string, number]> | Array<number>
    >()
    total_filters.set(
      FilterTitle.office,
      office_list.sort((a, b) => a[0].localeCompare(b[0]))
    )
    total_filters.set(
      FilterTitle.practice,
      practice_list.sort((a, b) => a[0].localeCompare(b[0]))
    )
    total_filters.set(
      FilterTitle.laborCategory,
      laborCategory_list.sort((a, b) => a[0].localeCompare(b[0]))
    )
    if (resources.length > 0) {
      total_filters.set(FilterTitle.totalEmploymentYears, totalEmpYearsRange)
      total_filters.set(FilterTitle.yearsWithHks, yearsHksRange)
    }
    return total_filters
  }

  render() {
    if (this.props.match.params.id) {
      return <ResourceDetailsPage id={this.props.match.params.id} />
    } else
      return (
        <div className="w-80 center">
          {' '}
          <div className="project-page-heading pb2">Bench</div>
          <div className="center main-box">
            <Search
              changeSearch={this.changeSearch}
              title="Employees"
              placeholder="Search Employees"
              initValue={this.state.searchText}
            />
            {this.state.resources ? (
              <ResourcesFilterList
                filters={this.pruneFilterLists(
                  this.state.resources,
                  this.state.filterBy
                )}
                resources={this.state.resources}
                filterBy={this.state.filterBy}
                sortBy={this.state.sortBy}
                onFilterToggle={this.onFilterToggle}
                onRangeChange={this.onRangeChange}
                onClearFilters={this.clearFilters}
                onSortChanged={this.onSortChanged}
                searchText={this.state.searchText}
              />
            ) : (
              <Resources.Component
                variables={{ searchFor: this.state.searchText }}
                fetchPolicy="network-only"
                onCompleted={data =>
                  this.setState({ resources: data.resources })
                }
              >
                {({ loading, error, data }) => {
                  if (loading) return <Spinner />
                  if (error) return <HKSError />
                  const resources = data.resources
                  return resources.length > 0 ? (
                    <ResourcesFilterList
                      filters={this.pruneFilterLists(
                        resources,
                        this.state.filterBy
                      )}
                      resources={resources}
                      filterBy={this.state.filterBy}
                      sortBy={this.state.sortBy}
                      onFilterToggle={this.onFilterToggle}
                      onRangeChange={this.onRangeChange}
                      onClearFilters={this.clearFilters}
                      onSortChanged={this.onSortChanged}
                      searchText={this.state.searchText}
                    />
                  ) : (
                    <div className="zero-results">
                      {this.state.searchText
                        ? 'Sorry, no results found on the query "' +
                          this.state.searchText +
                          '"'
                        : 'Sorry, no results found'}
                    </div>
                  )
                }}
              </Resources.Component>
            )}
          </div>
        </div>
      )
  }
}

export default ResourcesBench
