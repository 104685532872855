export type Maybe<T> = T | null

// ====================================================
// Documents
// ====================================================

export namespace Resources {
  export type Variables = {
    searchFor?: Maybe<string>
  }

  export type Query = {
    __typename?: 'Query'

    resources: (Maybe<Resources>)[]
  }

  export type Resources = ResourceInfo.Fragment
}

export namespace Resource {
  export type Variables = {
    id: string
  }

  export type Query = {
    __typename?: 'Query'

    resource: Maybe<Resource>
  }

  export type Resource = ResourceInfo.Fragment & ResourceDetails.Fragment
}

export namespace ResourceInfo {
  export type Fragment = {
    __typename?: 'Resource'

    id: string

    hksId: string

    firstName: string

    lastName: string

    fullName: Maybe<string>

    thumbnailUrl: Maybe<string>

    office: Maybe<string>

    laborCategory: Maybe<string>

    practice: Maybe<string>

    profitCenter: Maybe<string>

    title: Maybe<string>

    yearsWithHks: Maybe<number>

    totalEmploymentYears: Maybe<number>

    team: Maybe<string>

    realRate: Maybe<number>

    genericRate: Maybe<number>

    fte: Maybe<number>

    onLeave: Maybe<boolean>
  }
}

export namespace ProjectInfo {
  export type Fragment = {
    __typename?: 'Project'

    id: string

    name: string

    projectNumber: string

    client: string

    practiceArea: Maybe<string>

    laborCategory: Maybe<string>

    startDate: Maybe<string>

    completionDate: Maybe<string>

    totalHours: Maybe<number>
  }
}

export namespace ProjectDetails {
  export type Fragment = {
    __typename?: 'Project'

    location: Maybe<Location>

    primaryCountry: Maybe<string>

    primaryOffice: Maybe<string>

    principalInCharge: Maybe<PrincipalInCharge>

    projectManager: Maybe<ProjectManager>

    contractType: Maybe<string>

    deliveryType: Maybe<string>

    services: Maybe<string>

    estimatedConstructionCosts: Maybe<string>

    projectCurrency: Maybe<string>

    billingCurrency: Maybe<string>
  }

  export type Location = {
    __typename?: 'Address'

    city: string

    state: Maybe<string>

    zip: Maybe<string>

    county: Maybe<string>

    country: string
  }

  export type PrincipalInCharge = {
    __typename?: 'Person'

    id: string

    name: string
  }

  export type ProjectManager = {
    __typename?: 'Person'

    id: string

    name: string
  }
}

export namespace ResourceDetails {
  export type Fragment = {
    __typename?: 'Resource'

    registrations: Maybe<(Maybe<Registrations>)[]>

    currentProjects: Maybe<(Maybe<CurrentProjects>)[]>

    plannedProjects: Maybe<(Maybe<PlannedProjects>)[]>
  }

  export type Registrations = {
    __typename?: 'Registration'

    id: Maybe<string>

    licenseType: Maybe<string>

    country: Maybe<string>

    registrationNumber: Maybe<string>

    issueDate: Maybe<string>

    expirationDate: Maybe<string>
  }

  export type CurrentProjects = ProjectInfo.Fragment

  export type PlannedProjects = ProjectInfo.Fragment
}

import * as ReactApollo from 'react-apollo'
import * as React from 'react'

import gql from 'graphql-tag'

// ====================================================
// Fragments
// ====================================================

export namespace ResourceInfo {
  export const FragmentDoc = gql`
    fragment ResourceInfo on Resource {
      id
      hksId
      firstName
      lastName
      fullName
      thumbnailUrl
      office
      laborCategory
      practice
      profitCenter
      title
      yearsWithHks
      totalEmploymentYears
      team
      realRate
      genericRate
      fte
      onLeave
    }
  `
}

export namespace ProjectDetails {
  export const FragmentDoc = gql`
    fragment ProjectDetails on Project {
      location {
        city
        state
        zip
        county
        country
      }
      primaryCountry
      primaryOffice
      principalInCharge {
        id
        name
      }
      projectManager {
        id
        name
      }
      contractType
      deliveryType
      services
      estimatedConstructionCosts
      projectCurrency
      billingCurrency
    }
  `
}

export namespace ProjectInfo {
  export const FragmentDoc = gql`
    fragment ProjectInfo on Project {
      id
      name
      projectNumber
      client
      practiceArea
      laborCategory
      startDate
      completionDate
      totalHours
    }
  `
}

export namespace ResourceDetails {
  export const FragmentDoc = gql`
    fragment ResourceDetails on Resource {
      registrations {
        id
        licenseType
        country
        registrationNumber
        issueDate
        expirationDate
      }
      currentProjects {
        ...ProjectInfo
      }
      plannedProjects {
        ...ProjectInfo
      }
    }

    ${ProjectInfo.FragmentDoc}
  `
}

// ====================================================
// Components
// ====================================================

export namespace Resources {
  export const Document = gql`
    query resources($searchFor: String) {
      resources(searchFor: $searchFor) {
        ...ResourceInfo
      }
    }

    ${ResourceInfo.FragmentDoc}
  `
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...(this as any)['props'] as any}
        />
      )
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    )
  }
}
export namespace Resource {
  export const Document = gql`
    query resource($id: ID!) {
      resource(id: $id) {
        ...ResourceInfo
        ...ResourceDetails
      }
    }

    ${ResourceInfo.FragmentDoc}
    ${ResourceDetails.FragmentDoc}
  `
  export class Component extends React.Component<
    Partial<ReactApollo.QueryProps<Query, Variables>>
  > {
    render() {
      return (
        <ReactApollo.Query<Query, Variables>
          query={Document}
          {...(this as any)['props'] as any}
        />
      )
    }
  }
  export type Props<TChildProps = any> = Partial<
    ReactApollo.DataProps<Query, Variables>
  > &
    TChildProps
  export function HOC<TProps, TChildProps = any>(
    operationOptions:
      | ReactApollo.OperationOption<
          TProps,
          Query,
          Variables,
          Props<TChildProps>
        >
      | undefined
  ) {
    return ReactApollo.graphql<TProps, Query, Variables, Props<TChildProps>>(
      Document,
      operationOptions
    )
  }
}
