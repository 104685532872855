import React, { Component } from 'react'

interface FilterCollapsibleProps {
  title: string
  entries: Array<[string, number]>
  filtersChecked: Array<string>
  onFilterToggle: (title: string, value: string) => void
}

class FilterCollapsible extends Component<FilterCollapsibleProps> {
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  render() {
    const { title, entries, filtersChecked } = this.props
    const elementId = title + '_collapsible'
    return (
      <div className="wrap-collabsible mt3">
        <input
          id={elementId}
          className="collapsible toggle"
          type="checkbox"
          defaultChecked={filtersChecked.length > 0}
        />
        <label htmlFor={elementId} className="lbl-toggle filter-title">
          {title}
        </label>
        <div className="collapsible-content">
          <ul className="content-inner">
            {entries.map((e, index) => (
              <div key={index} className="dt w-100 pb2">
                <div className="dtc w-10 ">
                  <input
                    type="checkbox"
                    checked={filtersChecked.includes(e[0])}
                    className="checkbox-rectangle v-mid"
                    value={e[0]}
                    onChange={() => this.props.onFilterToggle(title, e[0])}
                  />
                </div>
                <div className="dtc projectNumber pl1">{e[0]}</div>
                <div className="dtc filter-number pl3 w-10">{e[1]}</div>
              </div>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default FilterCollapsible
