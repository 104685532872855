import React, { Component } from 'react'
import ResourceThumbnail from './ResourceThumbnail'

import { ResourceInfo } from '../../schema/types.d'
import Pagination, { PaginationState } from './Pagination'

interface ListProps {
  res: ResourceInfo.Fragment[]
  sortBy: string
  onSortChanged: (e) => void
  searchText: string
}

export enum SortBy {
  default = 'laborCategory',
  firstName = 'firstName',
  lastName = 'lastName'
}

interface ListState {
  currentPage: number
  totalPages: number
}

class ResourcesList extends Component<ListProps, ListState> {
  pageLimit = 40

  constructor(props: ListProps) {
    super(props)
    this.state = {
      currentPage: null,
      totalPages: null
    }
    this.onPageChanged = this.onPageChanged.bind(this)
  }

  onPageChanged = (data: PaginationState) => {
    const { currentPage, totalPages } = data
    this.setState({ currentPage, totalPages })
  }

  resourceListHeader(totalCount: number) {
    const { currentPage } = this.state
    const end_resource_no =
      currentPage * this.pageLimit > totalCount
        ? totalCount
        : currentPage * this.pageLimit
    const start_resource_no =
      totalCount == 0
        ? totalCount
        : currentPage * this.pageLimit - this.pageLimit + 1
    return (
      <div className="pa3 dt">
        <div className="dtc projectNumber">
          Viewing {start_resource_no}-{end_resource_no} of {totalCount}
          <div className="dtc line-3 ml3" />
        </div>
        <div className="dtc sort-by pr2"> Sort by:</div>
        <div className="dtc">
          <select
            className="pl2 projectNumber sort-box"
            value={this.props.sortBy}
            onChange={e => this.props.onSortChanged(e)}
          >
            <option value={SortBy.default}>Labor Category</option>
            <option value={SortBy.firstName}>First Name</option>
            <option value={SortBy.lastName}>Last Name</option>
          </select>
        </div>
      </div>
    )
  }

  sortedResources(sortBy: string, resources: ResourceInfo.Fragment[]) {
    switch (sortBy) {
      case SortBy.lastName:
        return resources.sort(
          (a, b) =>
            a[SortBy.lastName].localeCompare(b[SortBy.lastName]) ||
            a[SortBy.firstName].localeCompare(b[SortBy.firstName])
        )
      case SortBy.firstName:
        return resources.sort(
          (a, b) =>
            a[SortBy.firstName].localeCompare(b[SortBy.firstName]) ||
            a[SortBy.lastName].localeCompare(b[SortBy.lastName])
        )
      default:
        return resources.sort(
          (a, b) =>
            a[SortBy.default].localeCompare(b[SortBy.default]) ||
            a[SortBy.lastName].localeCompare(b[SortBy.lastName]) ||
            a[SortBy.firstName].localeCompare(b[SortBy.firstName])
        )
    }
  }

  render() {
    const { currentPage } = this.state
    const resources = [...this.props.res]
    const sortedResources =
      this.props.sortBy === SortBy.default
        ? resources
        : this.sortedResources(this.props.sortBy, resources)
    const totalCount = sortedResources.length

    const offset = (currentPage - 1) * this.pageLimit
    const currentResources = sortedResources.slice(
      offset,
      offset + this.pageLimit
    )

    return (
      <div className="bullpen fr">
        {this.resourceListHeader(totalCount)}
        <div className="flex flex-wrap center">
          {totalCount > 0 ? (
            currentResources.map(resource => (
              <ResourceThumbnail key={resource.id} resource={resource} />
            ))
          ) : (
            <div className="zero-results">
              {this.props.searchText
                ? 'Sorry, no results found on the query "' +
                  this.props.searchText +
                  '"'
                : 'Sorry, no results found'}
            </div>
          )}
        </div>
        <div className="mt3 fl">
          <Pagination
            totalRecords={totalCount}
            pageLimit={this.pageLimit}
            pageNeighbours={1}
            onPageChanged={this.onPageChanged}
          />
        </div>
        <div />
      </div>
    )
  }
}

export default ResourcesList
