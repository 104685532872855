import React, { Component } from 'react'
import { ResourceDetails as ResourceDetailsM } from '../../schema/types.d'

interface RegistrationsProps {
  registrations: ResourceDetailsM.Registrations[]
}

class Registrations extends Component<RegistrationsProps> {
  formatDate(dateString: string) {
    let d = new Date(parseInt(dateString))
    return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
  }

  render() {
    return (
      <article className="registrations pl5 pa4 dt">
        {this.props.registrations.map((r, index) => (
          <li key={index} className="pb2">
            <div className="dtc arrow-list"> > </div>
            <div className="dtc">
              {' '}
              <div className="dt-row license">{r.licenseType}</div>
              <div className="dt-row license-sub">
                {r.registrationNumber
                  ? 'Member #: ' + r.registrationNumber
                  : ''}
                {r.issueDate && r.issueDate.length > 0
                  ? ' | Joined: ' + this.formatDate(r.issueDate)
                  : ''}
              </div>
            </div>
          </li>
        ))}
      </article>
    )
  }
}

export default Registrations
