import React, { Component } from 'react'
import FilterCollapsible from './FilterCollapsible'
import RangeSlider from './RangeSlider'

interface FilterListProps {
  filters: Map<string, any[]>
  filterBy: Map<string, any[]>
  onFilterToggle: (title: string, value: string) => void
  onRangeChange: (title: string, range: number[]) => void
  onClearFilters: () => void
}
class FilterList extends Component<FilterListProps> {
  render() {
    const { filters, filterBy } = this.props
    const [a, b, c, d, e] = [...filters.keys()]
    return (
      <div className="filter-column">
        <div
          className="clear-filters  dim pointer fr mb2 pr2"
          onClick={this.props.onClearFilters.bind(this)}
        >
          Clear Filters
        </div>
        {[a, b, c].map(k => (
          <div key={k}>
            <FilterCollapsible
              title={k}
              entries={filters.get(k)}
              onFilterToggle={this.props.onFilterToggle}
              filtersChecked={filterBy.get(k)}
            />
            <div className="line-4" />
          </div>
        ))}
        {[d, e].map(k =>
          filters.get(k) ? (
            <div key={k}>
              <RangeSlider
                title={k}
                range={filters.get(k)}
                onRangeChange={this.props.onRangeChange}
                rangeSelections={filterBy.get(k)}
              />
              <div className="line-4" />
            </div>
          ) : (
            ''
          )
        )}
      </div>
    )
  }
}
export default FilterList
